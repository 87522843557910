import { useEffect } from "react";
import { v1 as uuid } from "uuid";
import * as Sentry from "@sentry/react";
import axios from "axios";

import { useStorage } from "./useStorage";
import { UserInfo } from "../types/domain";

export const useSentryIdentify = (
  storageKey: string,
  userInfo: UserInfo | null
) => {
  const [sessionId, setSessionId] = useStorage<string | null>(
    storageKey,
    null,
    true
  );

  useEffect(() => {
    if (userInfo?.data) {
      const { userId, email, firstName, lastName } = userInfo.data;
      const newSessionId = sessionId || uuid();

      setSessionId(newSessionId);
      Sentry.setTag("sessionId", newSessionId);
      axios.defaults.headers["X-Session-Id"] = newSessionId;

      Sentry.setUser({
        email,
        id: userId?.toString(),
        username: [firstName, lastName].filter(Boolean).join(" "),
      });
    } else {
      const scope = Sentry.getCurrentScope();

      scope.setUser(null);
      scope.setTag("sessionId", undefined);
      setSessionId(null);

      delete axios.defaults.headers["X-Session-Id"];
    }
  }, [userInfo]); // eslint-disable-line

  return sessionId;
};

export default useSentryIdentify;
